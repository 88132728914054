@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --navbar-height: 56px;
}

@layer base {
  body {
    margin: 0;
    font-family:
      'IBM Plex Sans',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  #root {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply m-0;
  }
  p {
    @apply m-0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    margin: 0;
    padding: 0;
  }
}
@layer components {
  .typography-h1 {
    @apply text-[48px] leading-[56px];
  }
  .typography-h1-special {
    @apply text-[96px] leading-[124px];
  }
  .typography-h2 {
    @apply text-[32px] leading-[40px];
  }
  .typography-h2-special {
    @apply text-[32px] leading-[48px];
  }
  .typography-h3 {
    @apply text-[24px] leading-[30px];
  }
  .typography-h4 {
    @apply text-[20px] leading-[26px];
  }
  .typography-caption {
    @apply text-[14px] leading-[18px];
  }
  .typography-caption-small {
    @apply text-[12px] leading-[16px];
  }
  .typography-caption-text {
    @apply text-[16px] leading-[20px];
  }
  .title {
    @apply typography-h1 text-primary font-bold;
  }
  .link {
    @apply cursor-pointer text-blue-500;
  }
  .tab-label {
    @apply typography-h2 font-bold;
  }
  .breadcrumb {
    color: rgb(128, 128, 128);
    font-size: 12px;
  }
  .bell {
    animation: bellShake 1s;
    animation-iteration-count: 4;
    transform-origin: center;
  }
  @keyframes bellShake {
    0% {
      transform: rotate(0);
    }
    15% {
      transform: rotate(15deg);
    }
    30% {
      transform: rotate(-15deg);
    }
    45% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(5deg);
    }
    85% {
      transform: rotate(-5deg);
    }
    92% {
      transform: rotate(1deg);
    }
    100% {
      transform: rotate(0);
    }
  }
  .content {
    height: calc(100vh - var(--navbar-height));
    overflow-y: scroll;
    position: relative;
  }
  .dashboard-input {
    @apply rounded bg-[#F7F7F7] text-primary typography-caption pt-3;
  }
  .dashboard-input > .ant-row > .ant-col > label {
    @apply text-primary text-center block;
  }
  .ant-menu-item {
    @apply flex items-center gap-1;
  }
}
/* ANTD */
.ant-layout-content {
  padding: 16px;
}

.ant-divider {
  margin: 8px 0;
}

.ant-list-item {
  padding: 0;
}

.ant-steps-item-active .ant-steps-icon-dot {
  background: greenyellow !important;
  width: 16px !important;
  height: 16px !important;
  margin-left: -4px !important;
  margin-top: -4px !important;
}

.ant-steps-item-wait .ant-steps-icon-dot {
  background: none !important;
}

.ant-steps-item.ant-steps-item-wait .ant-steps-icon-dot::after {
  content: 'N/A' !important;
  top: 0 !important;
}

.ant-form-item-label {
  padding-bottom: 4px !important;
}

.ant-form-item {
  margin-bottom: 24px !important;
}

#create-service-unit > .ant-form-item {
  margin-bottom: 8px !important;
}

.ant-card {
  border-radius: 4px;
}

.ant-upload-list-item-container {
  width: 400px;
}

.ant-table-column-sorters {
  @apply w-fit gap-1;
}

.highlight-bottom-border > td {
  @apply border-b-state-neutral !important;
}

.highlight-top-border > td {
  @apply border-t-state-neutral !important;
}

.ant-descriptions-title {
  @apply typography-h3 font-semibold text-secondary !important;
}
.ant-descriptions-item-label {
  @apply text-secondary !important;
}
.ant-descriptions-item-content {
  @apply text-secondary typography-h3 font-semibold !important;
}

.ant-tree-directory .ant-tree-switcher {
  width: 16px;
}
.ant-tree-directory .ant-tree-node-content-wrapper {
  padding-left: 0;
}

*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

.legend-p > div > li > p {
  white-space: normal !important;
}

/*# sourceMappingURL=index.css.map */
